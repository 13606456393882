var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var MainSummaryImage = styled.img(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\twidth: 100%;\n\theight: auto;\n"], ["\n\twidth: 100%;\n\theight: auto;\n"])));
export var GalleryContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\tpadding-left: 40px;\n\tpadding-bottom: 4px;\n\twidth: 561px;\n"], ["\n\tpadding-left: 40px;\n\tpadding-bottom: 4px;\n\twidth: 561px;\n"])));
export var GallerySecondaryImage = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\twidth: 136.23px;\n\tmargin-right: 4px;\n"], ["\n\twidth: 136.23px;\n\tmargin-right: 4px;\n"])));
export var GalleryMainImage = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject([""], [""])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
