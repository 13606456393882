var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { RegionPage } from "./pages/Region";
import MainSummaryPage from "./pages/MainSummary";
function App() {
    return (_jsx(Router, { children: _jsxs(Switch, { children: [_jsx(Route, __assign({ path: ["/:regionId/itinerary/:itinId", "/:regionId/:venueId", "/:regionId"] }, { children: _jsx(RegionPage, {}, void 0) }), void 0), _jsx(Route, __assign({ path: "/", exact: true }, { children: _jsx(MainSummaryPage, {}, void 0) }), void 0)] }, void 0) }, void 0));
}
export default App;
