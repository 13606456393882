var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import { mobileBP } from "../MainLayout";
var GREEN = "#4aa27c"; // better location?
export var ButtonContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tdisplay: flex;\n\tflex-wrap: wrap;\n\tgap: var(--prm);\n"], ["\n\tdisplay: flex;\n\tflex-wrap: wrap;\n\tgap: var(--prm);\n"])));
export var FilledButton = styled.button(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\tbackground-color: ", ";\n\tcolor: #ffffff;\n\tfont-size: 15px;\n\tfont-weight: bold;\n\tborder: 2px solid ", ";\n\tmargin-right: 30px;\n\tpadding: 10px 20px 10px 20px;\n\tborder-radius: 4px;\n\ttransition: all 200ms ease-in-out;\n\n\t:hover {\n\t\tcursor: pointer;\n\t\tbackground-color: white;\n\t\tcolor: ", ";\n\t}\n"], ["\n\tbackground-color: ", ";\n\tcolor: #ffffff;\n\tfont-size: 15px;\n\tfont-weight: bold;\n\tborder: 2px solid ", ";\n\tmargin-right: 30px;\n\tpadding: 10px 20px 10px 20px;\n\tborder-radius: 4px;\n\ttransition: all 200ms ease-in-out;\n\n\t:hover {\n\t\tcursor: pointer;\n\t\tbackground-color: white;\n\t\tcolor: ", ";\n\t}\n"])), GREEN, GREEN, GREEN);
export var Button = styled.a(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\tdisplay: inline-block;\n\ttext-decoration: none;\n\tbackground-color: #ffffff;\n\tcolor: #4aa27c;\n\tfont-size: 15px;\n\tfont-weight: bold;\n\tborder: 2px solid ", ";\n\tpadding: 10px 20px 10px 20px;\n\tborder-radius: 4px;\n\tcursor: pointer;\n\ttext-transform: uppercase;\n\ttransition: all 200ms ease-in-out;\n\n\t:hover {\n\t\tbackground-color: ", ";\n\t\tcolor: white;\n\t}\n\n\t@media screen and (min-width: ", ") {\n\t\tpadding: 8px 16px 8px 16px;\n\t}\n"], ["\n\tdisplay: inline-block;\n\ttext-decoration: none;\n\tbackground-color: #ffffff;\n\tcolor: #4aa27c;\n\tfont-size: 15px;\n\tfont-weight: bold;\n\tborder: 2px solid ", ";\n\tpadding: 10px 20px 10px 20px;\n\tborder-radius: 4px;\n\tcursor: pointer;\n\ttext-transform: uppercase;\n\ttransition: all 200ms ease-in-out;\n\n\t:hover {\n\t\tbackground-color: ", ";\n\t\tcolor: white;\n\t}\n\n\t@media screen and (min-width: ", ") {\n\t\tpadding: 8px 16px 8px 16px;\n\t}\n"])), GREEN, GREEN, mobileBP);
var templateObject_1, templateObject_2, templateObject_3;
