var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ArrowDir, BaseUrl, LocationType } from "../client";
import pinSVG from "../svgs/pin_arrow.svg";
var Tick = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tposition: relative;\n\twidth: 0;\n\theight: 0;\n\tleft: 12px;\n\tz-index: 30;\n\tborder: 0.5rem solid transparent;\n\t", "\n"], ["\n\tposition: relative;\n\twidth: 0;\n\theight: 0;\n\tleft: 12px;\n\tz-index: 30;\n\tborder: 0.5rem solid transparent;\n\t", "\n"])), function (p) { return (p.top ? "top: -40px; " + "border-top-color: #fff;" : "border-bottom-color: #fff;"); });
var Bubble = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\tposition: relative;\n\tbottom: 0.5rem;\n\tpointer-events: none;\n\ttransition: opacity 250ms ease-in-out;\n\t&.is-hidden {\n\t\topacity: 0;\n\t\tpointer-events: none;\n\n\t\t& * {\n\t\t\tpointer-events: none;\n\t\t\tcursor: pointer;\n\t\t}\n\t}\n\t&.is-active {\n\t\tz-index: 62;\n\t}\n"], ["\n\tposition: relative;\n\tbottom: 0.5rem;\n\tpointer-events: none;\n\ttransition: opacity 250ms ease-in-out;\n\t&.is-hidden {\n\t\topacity: 0;\n\t\tpointer-events: none;\n\n\t\t& * {\n\t\t\tpointer-events: none;\n\t\t\tcursor: pointer;\n\t\t}\n\t}\n\t&.is-active {\n\t\tz-index: 62;\n\t}\n"])));
//   ${(p) => (p.onClick ? ":hover { cursor: pointer; }" : "")}
var BubbleText = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\tbackground: #fff;\n\tborder-radius: 4px;\n\tpadding: 8px;\n\tposition: relative;\n\tpointer-events: all;\n\tbox-shadow: 0 10px 60px 0 rgba(0, 0, 0, 0.2);\n\tfont-family: \"Manrope\", sans-serif;\n\tright: ", ";\n\t", ";\n\t", "\n\tz-index: 5;\n"], ["\n\tbackground: #fff;\n\tborder-radius: 4px;\n\tpadding: 8px;\n\tposition: relative;\n\tpointer-events: all;\n\tbox-shadow: 0 10px 60px 0 rgba(0, 0, 0, 0.2);\n\tfont-family: \"Manrope\", sans-serif;\n\tright: ", ";\n\t", ";\n\t", "\n\tz-index: 5;\n"])), function (p) { return (p.left ? "calc(100% - 40px)" : "8px"); }, function (p) { return (p.top ? "transform: translateY(calc(-100% - 39px - 1rem));" : ""); }, function (p) { return (p.onClick ? ":hover { cursor: pointer; }" : ""); });
var LocationCircle = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n\tposition: relative;\n\theight: 40px;\n\twidth: 40px;\n\tbackground-color: ", ";\n\tborder-radius: 50%;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\tz-index: 2;\n\tcolor: #ececec;\n\ttransition: all 250ms ease-in-out;\n\t&.is-active {\n\t\tbackground-color: white;\n\t\tcolor: ", ";\n\t\tz-index: 60;\n\t}\n\t&.is-hidden {\n\t\topacity: 0;\n\t\tpointer-events: none;\n\t}\n\t", "\n"], ["\n\tposition: relative;\n\theight: 40px;\n\twidth: 40px;\n\tbackground-color: ", ";\n\tborder-radius: 50%;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\tz-index: 2;\n\tcolor: #ececec;\n\ttransition: all 250ms ease-in-out;\n\t&.is-active {\n\t\tbackground-color: white;\n\t\tcolor: ", ";\n\t\tz-index: 60;\n\t}\n\t&.is-hidden {\n\t\topacity: 0;\n\t\tpointer-events: none;\n\t}\n\t", "\n"])), function (p) { return typeToColor(p.type); }, function (p) { return typeToColor(p.type); }, function (p) { return (p.onClick ? "cursor: pointer;" : ""); });
var Arrow = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n\tposition: absolute;\n\tz-index: 61;\n\tdisplay: flex;\n\talign-content: center;\n\tjustify-content: center;\n\n\t::after {\n\t\tborder-radius: 50%;\n\t\tbackground-color: ", ";\n\t\tdisplay: block;\n\t\theight: 15px;\n\t\twidth: 15px;\n\t\ttext-align: center;\n\t\tcontent: url(", ");\n\t}\n\n\t", ";\n"], ["\n\tposition: absolute;\n\tz-index: 61;\n\tdisplay: flex;\n\talign-content: center;\n\tjustify-content: center;\n\n\t::after {\n\t\tborder-radius: 50%;\n\t\tbackground-color: ", ";\n\t\tdisplay: block;\n\t\theight: 15px;\n\t\twidth: 15px;\n\t\ttext-align: center;\n\t\tcontent: url(", ");\n\t}\n\n\t", ";\n"])), function (p) { return typeToColor(p.type); }, pinSVG, function (p) {
    switch (p.direction) {
        case ArrowDir.Up:
            return "top: -6px;";
        case ArrowDir.Down:
            return "bottom: -6px; transform: rotate(180deg);";
        case ArrowDir.Left:
            return "left: -6px; transform: rotate(-90deg);";
        case ArrowDir.Right:
            return "right: -6px; transform: rotate(90deg);";
        default:
            return "";
    }
});
var Line1 = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n\tfont-weight: 700;\n\tfont-size: 15px;\n"], ["\n\tfont-weight: 700;\n\tfont-size: 15px;\n"])));
function typeToColor(lt) {
    switch (lt) {
        case LocationType.Venue:
        case LocationType.Region:
            return "#4AA27C";
        case LocationType.Activity:
            return "#4A76A2";
        case LocationType.Attraction:
            return "#DC8A00";
    }
}
var Line2 = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n\tfont-weight: 600;\n\tfont-size: 12px;\n\tcolor: ", ";\n"], ["\n\tfont-weight: 600;\n\tfont-size: 12px;\n\tcolor: ", ";\n"])), function (p) { return typeToColor(p.type); });
var IconContainer = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n\t& > svg {\n\t\tmax-width: 26px;\n\t\tmax-height: 26px;\n\t}\n"], ["\n\t& > svg {\n\t\tmax-width: 26px;\n\t\tmax-height: 26px;\n\t}\n"])));
export function LocationPin(_a) {
    var x = _a.x, y = _a.y, left = _a.left, top = _a.top, type = _a.type, line1 = _a.line1, line2 = _a.line2, img = _a.img, showInfo = _a.showInfo, onClick = _a.onClick, arrow = _a.arrow, isActive = _a.isActive, isVisible = _a.isVisible;
    var _b = useState(false), hovering = _b[0], setHovering = _b[1];
    var _c = useState(String), svgResponse = _c[0], setSVG = _c[1];
    var showText = isVisible && (showInfo || hovering);
    var realOnClick;
    if (onClick) {
        realOnClick = function (e) {
            e.stopPropagation();
            onClick();
        };
    }
    function mouseEnter() {
        setHovering(true);
    }
    var mouseLeave = function (e) {
        setHovering(false);
    };
    useEffect(function () {
        var iconUrl = BaseUrl + img;
        fetch(iconUrl)
            .then(function (response) { return response.text(); })
            .then(function (svg) {
            setSVG(svg);
        });
    });
    var xAdjusted = Math.max(Math.min(96.5, x), 3.5);
    var yAdjusted = Math.max(Math.min(96.5, y), 3.5);
    return (_jsx("div", __assign({ style: {
            position: "absolute",
            left: "calc(" + xAdjusted + "% - 20px) ",
            top: "calc(" + yAdjusted + "% - 20px)",
            pointerEvents: isVisible ? "all" : "none",
        } }, { children: _jsxs("div", __assign({ style: { position: "relative" } }, { children: [_jsxs(LocationCircle, __assign({ className: hovering ? "is-active" : isActive ? "is-active" : isVisible ? "" : "is-hidden", type: type, onClick: realOnClick, onMouseEnter: mouseEnter, onMouseLeave: mouseLeave }, { children: [arrow && _jsx(Arrow, { direction: arrow, type: type }, void 0), svgResponse && _jsx(IconContainer, { dangerouslySetInnerHTML: { __html: svgResponse } }, void 0)] }), void 0), _jsxs(Bubble, __assign({ className: showText ? (isActive || hovering ? "is-active" : "") : "is-hidden" }, { children: [_jsx(Tick, { top: top }, void 0), _jsxs(BubbleText, __assign({ left: left, top: top, onClick: realOnClick }, { children: [_jsx(Line1, { children: line1 }, void 0), line2 && _jsx(Line2, __assign({ type: type }, { children: line2.toUpperCase() }), void 0)] }), void 0)] }), void 0)] }), void 0) }), void 0));
}
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
