var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import hobart from "../svgs/hobart.svg";
import tassie from "../svgs/tassie.svg";
import launceston from "../svgs/launceston.svg";
import { LocationPin } from "./LocationPin";
import React from "react";
import styled from "styled-components";
import { LocationLines } from "./LocationLines";
import { LocationType } from "../client";
import { mobileBP } from "../MainLayout";
var ScrollContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\t@media screen and (max-width: ", ") {\n\t\toverflow: auto;\n\t\tmax-height: calc(100vh - 120px);\n\t}\n\t@media screen and (min-width: ", ") {\n\t\t/* overflow: hidden; */\n\t}\n"], ["\n\t@media screen and (max-width: ", ") {\n\t\toverflow: auto;\n\t\tmax-height: calc(100vh - 120px);\n\t}\n\t@media screen and (min-width: ", ") {\n\t\t/* overflow: hidden; */\n\t}\n"])), mobileBP, mobileBP);
var MapContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\tposition: relative;\n\t@media screen and (max-width: ", ") {\n\t\twidth: 200vw;\n\t\toverflow: hidden;\n\t}\n\t& > img {\n\t\twidth: 100%;\n\t\theight: auto;\n\t}\n"], ["\n\tposition: relative;\n\t@media screen and (max-width: ", ") {\n\t\twidth: 200vw;\n\t\toverflow: hidden;\n\t}\n\t& > img {\n\t\twidth: 100%;\n\t\theight: auto;\n\t}\n"])), mobileBP);
export var MapType;
(function (MapType) {
    MapType["Hobart"] = "south";
    MapType["Launceston"] = "north";
    MapType["Tassie"] = "tassie";
})(MapType || (MapType = {}));
function mapSrc(t) {
    switch (t) {
        case MapType.Hobart:
            return hobart;
        case MapType.Launceston:
            return launceston;
        default:
            return tassie;
    }
}
export function RegionMap(_a) {
    var locations = _a.locations, map = _a.map, onVenueClick = _a.onVenueClick, 
    // noClicks,
    selected = _a.selected, onCancel = _a.onCancel, showInfo = _a.showInfo;
    var showLines = selected && selected.type === LocationType.Venue;
    return (_jsx(ScrollContainer, { children: _jsxs(MapContainer, __assign({ onClick: function () { return onCancel === null || onCancel === void 0 ? void 0 : onCancel(); } }, { children: [_jsx("img", { src: mapSrc(map), alt: "Map" }, void 0), showLines && (_jsx(LocationLines, { locations: locations.filter(function (l) { return l.type !== LocationType.Venue; }), origin: selected, originIndex: selected.id }, void 0)), _jsx("div", { children: locations.map(function (data) {
                        if (selected) {
                            return renderPin(data, selected.id == data.id, filterPins(data));
                        }
                        return renderPin(data, false, filterPins(data));
                    }) }, void 0)] }), void 0) }, void 0));
    function filterPins(v) {
        return !showLines || v.id === (selected === null || selected === void 0 ? void 0 : selected.id) || v.type !== LocationType.Venue;
    }
    function renderPin(v, isActive, isVisible) {
        var _a, _b;
        var isRegion = v.type === LocationType.Region;
        var isVenue = v.type === LocationType.Venue;
        var isVenueOrRegion = isVenue || isRegion;
        var realShowInfo = showInfo || v.id === (selected === null || selected === void 0 ? void 0 : selected.id) || showLines || isVenueOrRegion;
        var idNum = parseInt(v.id);
        var onClick = function () { return onVenueClick === null || onVenueClick === void 0 ? void 0 : onVenueClick(v); };
        return (_jsx(LocationPin, { x: v.xCord, y: v.yCord, line1: v.name, type: v.type, arrow: v.arrow, line2: (_b = (_a = selected === null || selected === void 0 ? void 0 : selected.locationDistances.find(function (l) { return l.destinationId === idNum; })) === null || _a === void 0 ? void 0 : _a.distance) !== null && _b !== void 0 ? _b : (isRegion ? undefined : v.type), left: v.labelPosition.horizontal === "left", top: v.labelPosition.vertical === "top", showInfo: realShowInfo, img: v.iconUrl, onClick: onClick, isActive: isActive, isVisible: isVisible }, v.name));
    }
}
var templateObject_1, templateObject_2;
