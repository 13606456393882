var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { MainHeading } from "../components/Typography";
import { FilledButton } from "../components/Buttons";
import { MainSummaryImage } from "../components/Images";
import { client, LocationType } from "../client";
import { useHistory } from "react-router-dom";
import MainLayout from "../MainLayout";
import { MapType, RegionMap } from "../components/RegionMap";
import "../bootstrap-grid.css";
import { RichTextContainer } from "../components/RichText";
import { MainSummaryContainer } from "../components/Layout";
import { regionHref } from "../routes";
var ButtonContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tpadding-top: calc(var(--prm) * 1.5);\n"], ["\n\tpadding-top: calc(var(--prm) * 1.5);\n"])));
var RegionPins = [
    {
        region: "south",
        locationDistances: [],
        type: LocationType.Region,
        name: "Hobart",
        xCord: 58,
        yCord: 77,
        id: "0",
        slug: "south",
        labelPosition: { horizontal: "left", vertical: "top" },
        iconUrl: "/assets/static/svgs/Venue.svg",
    },
    {
        region: "north",
        locationDistances: [],
        type: LocationType.Region,
        name: "Launceston",
        slug: "north",
        xCord: 55,
        yCord: 43,
        id: "0",
        labelPosition: { horizontal: "left", vertical: "top" },
        iconUrl: "/assets/static/svgs/Venue.svg",
    },
];
export default function MainSummaryPage() {
    var _a = useState(), pageData = _a[0], setPageData = _a[1];
    var _b = useState([]), regions = _b[0], setRegions = _b[1];
    var router = useHistory();
    function loadPage() {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var pageAttr, pageId, data;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        pageAttr = (_a = document.querySelector("#root")) === null || _a === void 0 ? void 0 : _a.getAttribute("data-page-id");
                        pageId = pageAttr ? parseInt(pageAttr) : 0;
                        return [4 /*yield*/, client.query({
                                page: [
                                    { id: pageId },
                                    {
                                        title: 1,
                                        on_LandingPage: {
                                            intro: 1,
                                            headerImage: {
                                                rendition: [{ height: 640 }, { url: 1 }],
                                            },
                                        },
                                    },
                                ],
                                regions: { city: 1, cityDisplay: 1 },
                            })];
                    case 1:
                        data = (_b.sent()).data;
                        if (data) {
                            setPageData(data.page);
                            setRegions(data.regions);
                        }
                        return [2 /*return*/];
                }
            });
        });
    }
    useEffect(function () {
        loadPage();
    }, []);
    if (!pageData)
        return _jsx("div", {}, void 0);
    return (_jsx(MainLayout, { backTo: {}, map: _jsx(RegionMap, { locations: RegionPins, map: MapType.Tassie, onVenueClick: function (r) { return router.push(regionHref(r.region)); } }, void 0), summary: _jsxs(_Fragment, { children: [_jsx("div", { children: _jsx(MainSummaryImage, { src: pageData.headerImage.rendition.url }, void 0) }, void 0), _jsxs(MainSummaryContainer, { children: [_jsx(MainHeading, { children: pageData.title }, void 0), _jsx(RichTextContainer, { dangerouslySetInnerHTML: { __html: pageData.intro } }, void 0), _jsx(ButtonContainer, { children: regions.map(function (r) { return (_jsx(FilledButton, __assign({ onClick: function () { return router.push(regionHref(r.city)); } }, { children: r.cityDisplay }), r.city)); }) }, void 0)] }, void 0)] }, void 0) }, void 0));
}
var templateObject_1;
