var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var MainSummaryContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tpadding: var(--prm-lg) var(--prm) 0 var(--prm);\n"], ["\n\tpadding: var(--prm-lg) var(--prm) 0 var(--prm);\n"])));
export var PricingTable = styled.table(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\tborder: 1px solid #999;\n\tborder-collapse: collapse;\n\tmargin-bottom: 40px;\n"], ["\n\tborder: 1px solid #999;\n\tborder-collapse: collapse;\n\tmargin-bottom: 40px;\n"])));
export var TableRow = styled.tr(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\tborder: 1px solid #999;\n"], ["\n\tborder: 1px solid #999;\n"])));
export var TableHeading = styled.th(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n\tfont-family: \"Manrope\", sans-serif;\n\tfont-size: 16px;\n\tfont-weight: 700;\n\tborder: 1px solid rgb(0 0 0 / 10%);\n\tpadding: 18px 14px;\n\tletter-spacing: 0.08px;\n\twidth: 92px;\n"], ["\n\tfont-family: \"Manrope\", sans-serif;\n\tfont-size: 16px;\n\tfont-weight: 700;\n\tborder: 1px solid rgb(0 0 0 / 10%);\n\tpadding: 18px 14px;\n\tletter-spacing: 0.08px;\n\twidth: 92px;\n"])));
export var TableData = styled.td(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n\tfont-family: \"Manrope\", sans-serif;\n\tborder: 1px solid rgb(0 0 0 / 10%);\n\tpadding: 18px 14px;\n\ttext-align: center;\n\tfont-size: 16px;\n\tfont-weight: 300;\n\tletter-spacing: 0.08px;\n"], ["\n\tfont-family: \"Manrope\", sans-serif;\n\tborder: 1px solid rgb(0 0 0 / 10%);\n\tpadding: 18px 14px;\n\ttext-align: center;\n\tfont-size: 16px;\n\tfont-weight: 300;\n\tletter-spacing: 0.08px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
