var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import MainLayout from "../MainLayout";
import React, { useEffect, useState } from "react";
import { RegionMap } from "../components/RegionMap";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { apolloClient, client, LocationQuery } from "../client";
import { MainSummaryImage } from "../components/Images";
import { MainHeading, SubHeading } from "../components/Typography";
import styled from "styled-components";
import { RichTextContainer } from "../components/RichText";
import { MainSummaryContainer } from "../components/Layout";
import { itineraryHref, locationHref, regionHref } from "../routes";
import { Helmet } from "react-helmet";
import ImageGallery from "../components/ImageGallery";
import { GetLocation } from "../query/locationDisplay";
import { LoadingDiv } from "../components/Loading";
var ItinGrid = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tdisplay: grid;\n\tgrid-template-columns: 1fr 1fr;\n\tgap: 20px;\n"], ["\n\tdisplay: grid;\n\tgrid-template-columns: 1fr 1fr;\n\tgap: 20px;\n"])));
var ItinImg = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\theight: 125px;\n\twidth: 100%;\n\tobject-fit: cover;\n"], ["\n\theight: 125px;\n\twidth: 100%;\n\tobject-fit: cover;\n"])));
var Cont = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\tposition: relative;\n\ttext-align: center;\n\tcolor: white;\n\tdisplay: inline-block;\n\n\t:hover {\n\t\tcursor: pointer;\n\t}\n"], ["\n\tposition: relative;\n\ttext-align: center;\n\tcolor: white;\n\tdisplay: inline-block;\n\n\t:hover {\n\t\tcursor: pointer;\n\t}\n"])));
var ImageText = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n\tposition: absolute;\n\ttop: 0;\n\tleft: 0;\n\tbackground-color: rgba(0, 0, 0, 0.5);\n\tfont-family: \"Manrope\", sans-serif;\n\tfont-weight: 700;\n\tfont-size: 15px;\n\twidth: 100%;\n\theight: 125px;\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n"], ["\n\tposition: absolute;\n\ttop: 0;\n\tleft: 0;\n\tbackground-color: rgba(0, 0, 0, 0.5);\n\tfont-family: \"Manrope\", sans-serif;\n\tfont-weight: 700;\n\tfont-size: 15px;\n\twidth: 100%;\n\theight: 125px;\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n"])));
var SidebarContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n\tposition: relative;\n\t&:before {\n\t\tcursor: wait;\n\t\tcontent: \"\";\n\t\tbackground-color: rgba(0, 0, 0, 0.3);\n\t\tposition: absolute;\n\t\ttop: 0;\n\t\tleft: 0;\n\t\twidth: 100%;\n\t\theight: 100%;\n\t\tpointer-events: none;\n\t\topacity: 0;\n\t\ttransition:\n\t\t\ttransform 150ms ease-in-out,\n\t\t\topacity 150ms ease-in-out;\n\t}\n\t&.is-loading {\n\t\t&:before {\n\t\t\tz-index: 5;\n\t\t\tpointer-events: all;\n\t\t\topacity: 1;\n\t\t}\n\t}\n"], ["\n\tposition: relative;\n\t&:before {\n\t\tcursor: wait;\n\t\tcontent: \"\";\n\t\tbackground-color: rgba(0, 0, 0, 0.3);\n\t\tposition: absolute;\n\t\ttop: 0;\n\t\tleft: 0;\n\t\twidth: 100%;\n\t\theight: 100%;\n\t\tpointer-events: none;\n\t\topacity: 0;\n\t\ttransition:\n\t\t\ttransform 150ms ease-in-out,\n\t\t\topacity 150ms ease-in-out;\n\t}\n\t&.is-loading {\n\t\t&:before {\n\t\t\tz-index: 5;\n\t\t\tpointer-events: all;\n\t\t\topacity: 1;\n\t\t}\n\t}\n"])));
export function RegionPage() {
    var _a;
    var _b = useParams(), regionId = _b.regionId, venueId = _b.venueId, itinId = _b.itinId;
    var _c = useState(), regionData = _c[0], setRegionData = _c[1];
    var _d = useState([]), itineraries = _d[0], setItineraries = _d[1];
    var _e = useState(), venueData = _e[0], setVenueData = _e[1];
    var _f = useState(), itinData = _f[0], setItinData = _f[1];
    var _g = useState({
        text: "Regions",
        path: "/",
    }), backTo = _g[0], setBackTo = _g[1];
    var _h = useState(true), isFetching = _h[0], setFetching = _h[1];
    var router = useHistory();
    useEffect(function () {
        loadPage();
    }, [regionId]);
    useEffect(function () {
        if (venueId) {
            loadVenue(venueId);
        }
    }, [venueId]);
    useEffect(function () {
        if (itinId) {
            loadItinerary(itinId);
        }
    }, [itinId]);
    function loadVenue(venueSlug) {
        return __awaiter(this, void 0, void 0, function () {
            var result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setFetching(true);
                        return [4 /*yield*/, apolloClient.query({
                                query: GetLocation,
                                variables: {
                                    slug: venueSlug,
                                    thumbSize: "560x375",
                                    fullSize: "1024x800",
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        setVenueData(result.data.location);
                        setFetching(false);
                        if (itinData && regionData) {
                            setBackTo({
                                text: itinData.name,
                                path: itineraryHref(regionData.city, itinData.slug),
                            });
                        }
                        else if (regionData) {
                            setBackTo({
                                text: regionData.cityDisplay,
                                path: regionHref(regionData === null || regionData === void 0 ? void 0 : regionData.city),
                            });
                        }
                        return [2 /*return*/];
                }
            });
        });
    }
    function loadItinerary(itinId) {
        return __awaiter(this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setFetching(true);
                        return [4 /*yield*/, client.chain.query.intinerary({ slug: itinId }).execute({
                                name: 1,
                                slug: 1,
                                intro: 1,
                                headerImage: {
                                    rendition: [
                                        {
                                            height: 375,
                                        },
                                        { url: 1 },
                                    ],
                                },
                                items: LocationQuery,
                            })];
                    case 1:
                        data = _a.sent();
                        setFetching(false);
                        if (data) {
                            setItinData(data);
                            if (regionData) {
                                setBackTo({
                                    text: regionData.cityDisplay,
                                    path: regionHref(regionData.city),
                                });
                            }
                        }
                        return [2 /*return*/];
                }
            });
        });
    }
    var location = useLocation();
    function selectVenue(ven) {
        var clickCurrent = ven && location.pathname.includes(ven.slug);
        if (!clickCurrent) {
            router.push(ven ? locationHref(regionId, ven.slug) : regionHref(regionId));
        }
        else {
            router.push(regionHref(regionId));
        }
        if (!ven || clickCurrent) {
            setVenueData(undefined);
            setItinData(undefined);
            setBackTo({
                text: "regions",
                path: "/",
            });
        }
    }
    function loadPage() {
        return __awaiter(this, void 0, void 0, function () {
            var data, region_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setFetching(true);
                        return [4 /*yield*/, client.query({
                                region: [
                                    { city: regionId },
                                    {
                                        intro: 1,
                                        city: 1,
                                        cityDisplay: 1,
                                        headerImage: {
                                            rendition: [
                                                { fill: "560x375" },
                                                {
                                                    url: 1,
                                                },
                                            ],
                                        },
                                        locations: LocationQuery,
                                    },
                                ],
                                itineraries: {
                                    slug: 1,
                                    name: 1,
                                    region: 1,
                                    headerImage: {
                                        rendition: [
                                            {
                                                height: 200,
                                            },
                                            { url: 1 },
                                        ],
                                    },
                                },
                            })];
                    case 1:
                        data = (_a.sent()).data;
                        setFetching(false);
                        if (data) {
                            region_1 = data.region;
                            setRegionData(region_1);
                            setItineraries(data.itineraries.filter(function (i) { return i.region === region_1.cityDisplay; }));
                        }
                        return [2 /*return*/];
                }
            });
        });
    }
    var locations = (_a = regionData === null || regionData === void 0 ? void 0 : regionData.locations) !== null && _a !== void 0 ? _a : [];
    var sidebar = venueData ? (renderVenue(venueData)) : itinData ? (renderItinerary(itinData)) : regionData ? (renderRegion(regionData)) : isFetching ? (function () { return _jsx(LoadingDiv, {}, void 0); }) : (_jsx("div", {}, void 0));
    return (_jsx(MainLayout, { backTo: backTo, map: _jsx(RegionMap, { map: regionId, locations: locations, onVenueClick: selectVenue, selected: locations.find(function (l) { return l.slug === venueId; }), onCancel: function () { return selectVenue(undefined); } }, void 0), summary: sidebar }, void 0));
    function renderVenue(venueData) {
        return (_jsxs(SidebarContainer, __assign({ className: isFetching ? "is-loading" : "" }, { children: [_jsxs(Helmet, { children: [_jsxs("title", { children: [venueData.name, " | Business Events Tasmania"] }, void 0), _jsx("meta", { name: "description", content: venueData.searchDescription }, void 0)] }, void 0), _jsx(ImageGallery, { gallery: venueData.gallery, galleryId: venueData.name }, void 0), _jsxs(MainSummaryContainer, { children: [_jsx(MainHeading, { children: venueData.name }, void 0), venueData.sidebarContent.map(function (c) { return (_jsx(RichTextContainer, { dangerouslySetInnerHTML: { __html: c.value } }, void 0)); })] }, void 0)] }), void 0));
    }
    function renderRegion(regionData) {
        var itinerariesSection = null;
        if (itineraries.length > 0) {
            itinerariesSection = (_jsxs(_Fragment, { children: [_jsx(SubHeading, { children: "Explore the map or choose an intinerary below" }, void 0), _jsx(ItinGrid, { children: itineraries.map(function (it) { return (_jsxs(Cont, __assign({ onClick: function () { return router.push(itineraryHref(regionId, it.slug)); } }, { children: [_jsx(ItinImg, { src: it.headerImage.rendition.url }, void 0), _jsx(ImageText, { children: _jsx("span", { children: it.name }, void 0) }, void 0)] }), it.slug)); }) }, void 0)] }, void 0));
        }
        return (_jsxs(SidebarContainer, __assign({ className: isFetching ? "is-loading" : "" }, { children: [_jsx(Helmet, { children: _jsxs("title", { children: [regionData.cityDisplay, " | Business Events Tasmania"] }, void 0) }, void 0), _jsx("div", { children: _jsx(MainSummaryImage, { src: regionData.headerImage.rendition.url }, void 0) }, void 0), _jsxs(MainSummaryContainer, { children: [_jsx(MainHeading, { children: regionData.cityDisplay }, void 0), _jsx(RichTextContainer, { dangerouslySetInnerHTML: { __html: regionData.intro } }, void 0), itinerariesSection] }, void 0)] }), void 0));
    }
    function renderItinerary(itinData) {
        return (_jsxs(SidebarContainer, __assign({ className: isFetching ? "is-loading" : "" }, { children: [_jsx(Helmet, { children: _jsxs("title", { children: [itinData.name, " | Business Events Tasmania"] }, void 0) }, void 0), _jsx("div", { children: _jsx(MainSummaryImage, { src: itinData.headerImage.rendition.url }, void 0) }, void 0), _jsxs(MainSummaryContainer, { children: [_jsx(MainHeading, { children: itinData.name }, void 0), _jsx(RichTextContainer, { dangerouslySetInnerHTML: { __html: itinData.intro } }, void 0)] }, void 0)] }), void 0));
    }
}
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
