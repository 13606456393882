var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
var Line = styled.path(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tstroke-dasharray: 2000;\n\tstroke-dashoffset: 2000;\n\tanimation: ", " 1.2s ease-in-out forwards;\n\n\t@keyframes dash", " {\n\t\tto {\n\t\t\tstroke-dashoffset: 0;\n\t\t}\n\t}\n"], ["\n\tstroke-dasharray: 2000;\n\tstroke-dashoffset: 2000;\n\tanimation: ", " 1.2s ease-in-out forwards;\n\n\t@keyframes dash", " {\n\t\tto {\n\t\t\tstroke-dashoffset: 0;\n\t\t}\n\t}\n"])), function (p) { return "dash" + p.index; }, function (p) { return p.index; });
var LinesContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\tposition: absolute;\n\ttop: 0;\n\tleft: 0;\n\twidth: 100%;\n\theight: 100%;\n\tcolor: #4aa27c;\n"], ["\n\tposition: absolute;\n\ttop: 0;\n\tleft: 0;\n\twidth: 100%;\n\theight: 100%;\n\tcolor: #4aa27c;\n"])));
export function LocationLines(_a) {
    var locations = _a.locations, origin = _a.origin, originIndex = _a.originIndex;
    var sx = 1246.41 / 100;
    var sy = 939.42 / 100;
    var tx = 0;
    var ty = 0;
    var originX = origin.xCord * sx + tx;
    var originY = origin.yCord * sy + ty;
    return (_jsx(LinesContainer, { children: _jsx("svg", __assign({ width: "100%", height: "100%", viewBox: "0 0 1246.41 939.42", xmlns: "http://www.w3.org/2000/svg" }, { children: locations.map(doCurve) }), void 0) }, void 0));
    function doCurve(_a, ind) {
        var x = _a.xCord, y = _a.yCord;
        var endX = x * sx + tx;
        var endY = y * sy + ty;
        var cPoint = originY > endY ? originX + " " + endY : endX + " " + originY;
        return (_jsx(Line, { d: "M " + originX + " " + originY + " S " + cPoint + " " + endX + " " + endY, index: originIndex, stroke: "currentColor", strokeWidth: 2, fill: "transparent" }, ind));
    }
}
var templateObject_1, templateObject_2;
